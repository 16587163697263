$grid-gutter-base: 15px;

$grid-row-height: 44px;

$grid-container-margin-base: 0px;
$grid-container-padding-base: 16px;
$grid-container-padding-small: 20px;

$grid-container-size-big: 100%;
$grid-container-size-small: 770px;

$grid-margin-0: 4px;
$grid-margin-1: 8px;
$grid-margin-2: 12px;
$grid-margin-3: 16px;
$grid-margin-4: 24px;
$grid-margin-5: 28px;
$grid-margin-6: 30px;  // Yes.
$grid-margin-7: 36px;
$grid-margin-8: 40px;
$grid-margin-9: 44px;
$grid-margin-10: 50px;  // Yes.

