@import 'mixins';


.checkbox {
  @include checkbox;

  .input:checked + &__checkmark {
    border-color: transparent;
  }
}
