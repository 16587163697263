@import '~microscope-sass/lib/responsive';
@import '../../components/breadcrumbs/constants';
@import '../../components/header/constants';
@import '../../components/navigation-bar/constants';

@mixin sticky($offset: null, $position: 'top', $clear: false) {
  position: sticky;

  @if ($position == 'top') {
    @if ($offset) {
      top: calc(#{$offset});
    } @else {
      top: 0;
    }

    .navigation-bar--sticky ~ &,
    .navigation-bar--sticky ~ * & {
      @if ($offset) {
        @include responsive($position, calc(#{$header-height-mobile} + (#{$offset})), calc(#{$navigation-bar-min-height-tablet-stuck} + (#{$offset})));
      } @else {
        @include responsive($position, $header-height-mobile, $navigation-bar-min-height-tablet-stuck);
      }
    }

    .navigation-bar--sticky + .breadcrumbs ~ &,
    .navigation-bar--sticky + .breadcrumbs ~ * & {
      @include tablet {
        @if ($offset) {
          #{$position}: calc(#{$navigation-bar-min-height-tablet-stuck} + (#{$offset}) + #{$breadcrumbs-min-height});
        } @else {
          #{$position}: $navigation-bar-min-height-tablet-stuck + $breadcrumbs-min-height;
        }
      }
    }

    @if $clear {
      .navigation-bar--stuck ~ &,
      .navigation-bar--stuck ~ * & {
        margin-left: 0!important;
        margin-right: 0!important;
      }
    }
  }

  @else {
    #{$position}: $offset;
  }
}
