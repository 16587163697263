@import "~microscope-sass/lib/typography";

.link {
  @include body;
  @include anchor;

  &--big {
    @include body--big;
  }
}
