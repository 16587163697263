@import '../../settings/lib/ro-shade';
@import '~microscope-sass/lib/grid';
@import '~microscope-sass/lib/color';
@import '~microscope-sass/lib/responsive';
@import '~microscope-sass/lib/typography';
@import 'constants';

.form {
  &__header,
  &__body {
    @include container;
    @include container--small;
  }

  &--full-width &__header,
  &--full-width &__body {
    max-width: 100%;
  }

  &__header {
    padding: 0;
  }

  &__title {
    @include h1(auto);
    margin-top: $form-title-margin!important;
  }

  &--no-margin &__title {
    margin-top: 0!important;
  }

  &__subtitle {
    @include h2;
  }

  .intro {
    @include margin(true);
  }

  &__caption {
    @include h2(auto, padding);
    @include shade-color('dark-blue');
    font-weight: normal;
  }

  &__required-label {
    @include body;
  }

  &__legend {
    opacity: 0;
    position: absolute;
  }

  &__body {
    padding: 0;
  }

  &__fieldset {
    @include container-padding(small);
    @include shade(1, background-color);
    border: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__legend {
    margin: 0;
    padding: 0;
  }

  .form-control:not(.form-control--compact) {
    margin-top: $form-control-margin-top;
    margin-bottom: $form-control-margin-top;
  }

  &__actions {
    @include body;
    margin-top: $form-control-margin-top;

    @include tablet {
      margin-left: $form-control-spacing;
      padding-left: $label-width;
    }
  }

  &__actions--right {
    text-align: right;
  }

  &__actions .button {
    @include mobile-only {
      width: 100%;
    }
  }

  .form-control + &__actions .button {
    margin-top: $form-control-margin-top;
  }

  &__actions .button:not(:last-child) {
    margin-right: $form-control-spacing;
  }

  &__description {
    @include body;
    padding: $form-description-padding-h 0;
  }
}
