@import '../../settings/lib/ro-shade';
@import '~microscope-sass/lib/color';
@import '~microscope-sass/lib/grid';

$checkbox-size: 20px;
$radio-size: 22px;
$checkbox-spacing: $grid-margin-0;

$errorlist-color-error: #d52b1e;
$errorlist-color-icon: $color-background;
$errorlist-height-error: 70px;
$errorlist-size-icon: 34px;

$form-title-margin: 60px;

$form-control-margin-top: 14px;
$form-control-spacing: 30px;
$form-description-padding-h: 20px;

$help-text-padding-v: $grid-margin-1;

$input-size-icon: 16px;
$input-background-button: linear-gradient($color-primary, $color-primary) no-repeat right / $grid-row-height $grid-row-height;
$input-background-date: url('../components/form/date.svg') no-repeat center right #{($grid-row-height - $input-size-icon) / 2} / $input-size-icon;
$input-background-date-gray: url('../components/form/date-gray.svg') no-repeat center right #{($grid-row-height - $input-size-icon) / 2} / $input-size-icon;
$input-background-file: url('../components/form/file.svg') no-repeat center right #{($grid-row-height - $input-size-icon) / 2} / $input-size-icon;
$input-background-file-gray: url('../components/form/file-gray.svg') no-repeat center right #{($grid-row-height - $input-size-icon) / 2} / $input-size-icon;
$input-background-search: url('../components/form/search.svg') no-repeat center right #{($grid-row-height - $input-size-icon) / 2} / $input-size-icon;
$input-background-search-gray: url('../components/form/search-gray.svg') no-repeat center right #{($grid-row-height - $input-size-icon) / 2} / $input-size-icon;
$input-background-input: linear-gradient(transparent);
$input-color-placeholder: shade('4');
$input-height-small: $grid-margin-4;
$input-padding-h: 12px;

$select-size-icon: 10px;
$select-background-dropdown: url('../components/form/dropdown.svg') no-repeat center right #{($grid-row-height - $select-size-icon) / 2} / $select-size-icon;
$select-background-dropdown-gray: url('../components/form/dropdown-gray.svg') no-repeat center right #{($grid-row-height - $select-size-icon) / 2} / $select-size-icon;

$label-width: 144px;
