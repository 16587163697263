$icons: (
  hamburger: '\EA01',
  zoeken: '\EA02',
  activiteit: '\EA03',
  blog: '\EA04',
  delta-omhoog: '\EA06',
  delta-omlaag: '\EA07',
  faq: '\EA0B',
  externe-link: '\EA0A',
  kalender: '\EA0D',
  melding-attentie: '\EA0F',
  nieuws: '\EA11',
  pijl-links: '\EA12',
  publicatie: '\EA17',
  facebook: '\EA1A',
  social-twitter: '\EA20',
  vraagteken: '\EA21',
  video: '\EA22',
  twitterlogo: '\EA24',
  retweet: '\EA25',
  tweetreply: '\EA26',
  delta-rechts-light: '\EA2C',
  trash: '\EA31',
  social-youtube: '\EA38',
  bel: '\EA41',
  close: '\EA43',
);
