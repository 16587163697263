@import '~microscope-sass/lib/transform';


.image {
  max-width: 100%;

  &--rounded {
    @include rounded;
  }

  &__image {
    border-radius: inherit;
    width: inherit;
    height: inherit;
    object-fit: inherit;
  }
}
