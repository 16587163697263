$fa-font-path: '../fonts/';

/**
Webfonts

De webfonts zijn versies van de rijkshuisstijl lettertypen, die speciaal gemaakt zijn voor een optimale weergave op het
beeldscherm. Zowel de Rijksoverheid Sans als de Serif zijn als webfont beschikbaar.
 */


/**
Sans Web of Sans Serif?

Sans Web wordt toegepast voor langere (lees-)teksten. De Serif Web is vooral interessant voor de afwisseling in
bijvoorbeeld digitale magazines, in combinatie met de Sans Web. De webfont versies kunnen als regular, italic of bold
versie toegpast worden.

- De Rijksoverheidswebfonts mogen, net als het rijkslogo, uitsluitend voor Rijksoverheidspublicaties ingezet worden.
- Het is aan te bevelen ook een fall-back systeemfont te bepalen voor het geval het webfont niet getoond kan worden.
 */
@font-face {
  font-family: 'RO Sans';
  src: url('/static/rijkshuisstijl/fonts/RO-SansWebText-Regular.eot');
  src: url('/static/rijkshuisstijl/fonts/RO-SansWebText-Regular.eot?#iefix') format('eot'), url('/static/rijkshuisstijl/fonts/RO-SansWebText-Regular.ttf') format('truetype'), url('/static/rijkshuisstijl/fonts/RO-SansWebText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RO Sans';
  src: url('/static/rijkshuisstijl/fonts/RO-SansWebText-Bold.eot');
  src: url('/static/rijkshuisstijl/fonts/RO-SansWebText-Bold.eot?#iefix') format('eot'), url('/static/rijkshuisstijl/fonts/RO-SansWebText-Bold.ttf') format('truetype'), url('/static/rijkshuisstijl/fonts/RO-SansWebText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'RO Sans';
  src: url('/static/rijkshuisstijl/fonts/RO-SansWebText-Italic.eot');
  src: url('/static/rijkshuisstijl/fonts/RO-SansWebText-Italic.eot?#iefix') format('eot'), url('/static/rijkshuisstijl/fonts/RO-SansWebText-Italic.ttf') format('truetype'), url('/static/rijkshuisstijl/fonts/RO-SansWebText-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'RO Serif';
  src: url('/static/rijkshuisstijl/fonts/RO-SerifWeb-Regular.eot');
  src: url('/static/rijkshuisstijl/fonts/RO-SerifWeb-Regular.eot?#iefix') format('eot'), url('/static/rijkshuisstijl/fonts/RO-SerifWeb-Regular.ttf') format('truetype'), url('/static/rijkshuisstijl/fonts/RO-SerifWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RO Serif';
  src: url('/static/rijkshuisstijl/fonts/RO-SerifWeb-Bold.eot');
  src: url('/static/rijkshuisstijl/fonts/RO-SerifWeb-Bold.eot?#iefix') format('eot'), url('/static/rijkshuisstijl/fonts/RO-SerifWeb-Bold.ttf') format('truetype'), url('/static/rijkshuisstijl/fonts/RO-SerifWeb-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'RO Serif';
  src: url('/static/rijkshuisstijl/fonts/RO-SerifWeb-Italic.eot');
  src: url('/static/rijkshuisstijl/fonts/RO-SerifWeb-Italic.eot?#iefix') format('eot'), url('/static/rijkshuisstijl/fonts/RO-SerifWeb-Italic.ttf') format('truetype'), url('/static/rijkshuisstijl/fonts/RO-SerifWeb-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
    font-family: "RO Icons";
    font-style: normal;
    font-weight: bold;
    src: url("/static/rijkshuisstijl/fonts/ro-icons-1.11.woff") format("opentype"),url("/static/rijkshuisstijl/fonts/ro-icons-1.11.ttf") format("truetype")
}

$typography-default-hover-link: false;
$typography-size-border: 2px;

$typography-font-family-base: 'RO Sans', Calibri, sans-serif;

$typography-font-size-text-mobile: 16/16*1rem;
$typography-font-size-text-tablet: 16/16*1rem;
$typography-font-size-text-laptop: 16/16*1rem;
$typography-font-size-text-desktop: 18/16*1rem;

$typography-font-size-text-big-mobile: 20/16*1rem;
$typography-font-size-text-big-tablet: 21/16*1rem;
$typography-font-size-text-big-laptop: 21/16*1rem;
$typography-font-size-text-big-desktop: 24/16*1rem;

$typography-font-size-text-small-mobile: 12/16*1rem;
$typography-font-size-text-small-tablet: 12/16*1rem;
$typography-font-size-text-small-laptop: 12/16*1rem;
$typography-font-size-text-small-desktop: 14/16*1rem;

$typography-font-size-h1-mobile: 26/16*1rem;
$typography-font-size-h1-tablet: 28/16*1rem;
$typography-font-size-h1-laptop: 28/16*1rem;
$typography-font-size-h1-desktop: 32/16*1rem;

$typography-font-size-h2-mobile: 21/16*1rem;
$typography-font-size-h2-tablet: 21/16*1rem;
$typography-font-size-h2-laptop: 21/16*1rem;
$typography-font-size-h2-desktop: 24/16*1rem;

$typography-font-size-h3-mobile: 18/16*1rem;
$typography-font-size-h3-tablet: 18/16*1rem;
$typography-font-size-h3-laptop: 18/16*1rem;
$typography-font-size-h3-desktop: 20/16*1rem;

$typography-font-size-h4-mobile: 16/16*1rem;
$typography-font-size-h4-tablet: 16/16*1rem;
$typography-font-size-h4-laptop: 16/16*1rem;
$typography-font-size-h4-desktop: 18/16*1rem;

$typography-font-size-h5-mobile: $typography-font-size-text-mobile;
$typography-font-size-h5-tablet: $typography-font-size-text-tablet;
$typography-font-size-h5-laptop: $typography-font-size-text-laptop;
$typography-font-size-h5-desktop: $typography-font-size-text-desktop;

$typography-font-size-h6-mobile: $typography-font-size-text-mobile;
$typography-font-size-h6-tablet: $typography-font-size-text-tablet;
$typography-font-size-h6-laptop: $typography-font-size-text-laptop;
$typography-font-size-h6-desktop: $typography-font-size-text-desktop;


$typography-font-weight-text: 400;
$typography-font-weight-h: 700;


$typography-line-height-text-mobile: 22/16;
$typography-line-height-text-tablet: 22/16;
$typography-line-height-text-laptop: 26/16;
$typography-line-height-text-desktop: 29/19;

$typography-line-height-text-big-mobile: 26/20;
$typography-line-height-text-big-tablet: 32/21;
$typography-line-height-text-big-laptop: 32/21;
$typography-line-height-text-big-desktop: 36/24;

$typography-line-height-h1-mobile: 30/26;
$typography-line-height-h1-tablet: 32/28;
$typography-line-height-h1-laptop: 32/28;
$typography-line-height-h1-desktop: 36/32;

$typography-line-height-h2-mobile: 26/21;
$typography-line-height-h2-tablet: 26/21;
$typography-line-height-h2-laptop: 26/21;
$typography-line-height-h2-desktop: 29/24;

$typography-line-height-h3-mobile: 26/18;
$typography-line-height-h3-tablet: 26/18;
$typography-line-height-h3-laptop: 26/18;
$typography-line-height-h3-desktop: 29/20;

$typography-line-height-h4-mobile: 26/16;
$typography-line-height-h4-tablet: 26/16;
$typography-line-height-h4-laptop: 26/16;
$typography-line-height-h4-desktop: 29/18;


$typography-margin-base: .8125rem;
$typography-margin-h: 0;

$typography-margin-h1-mobile: 0px 0px .7692em;
$typography-margin-h1-tablet: 0px 0px .7692em;
$typography-margin-h1-laptop: 0px 0px 1em;
$typography-margin-h1-desktop: 0px 0px 1em;

$typography-margin-h2-mobile: .952em 0 .2em;
$typography-margin-h2-tablet: .952em 0 .2em;
$typography-margin-h2-laptop: 2em 0 .2em;
$typography-margin-h2-desktop: 2em 0 .2em;

$typography-margin-h3-mobile: 0px 0px 0px;
$typography-margin-h3-tablet: 0px 0px 0px;
$typography-margin-h3-laptop: 0px 0px 0px;
$typography-margin-h3-desktop: 0px 0px 0px;

$typography-margin-h4-mobile: 0px 0px 60px;
$typography-margin-h4-tablet: 0px 0px 60px;
$typography-margin-h4-laptop: 0px 0px 60px;
$typography-margin-h4-desktop: 0px 0px 60px;


$typography-margin-property-h: margin;


$typography-padding-table: 0;
