@import './settings/all';
@import './components/all';


@media print {
  * {
    font-family: "RO Serif" !important;
  }

  @page {
    margin: 1cm;
  }

  @page :first {
    margin-top: 52mm;
  }
}

